<ng-container *wpWait="data.image$ as image">
  <img [src]="image">
  <mat-dialog-actions>
      <button *ngIf="images$ | async as images" mat-raised-button color="primary"
        [matBadge]="images.length" matBadgeColor="accent"
        (click)="viewImages(images)">
      <mat-icon>collections</mat-icon>
      <span i18n>VIEW IMAGES</span>
    </button>
    <!-- <app-message *ngIf="addImage$ | async as status; else addButton">{{status}}</app-message> -->

    <!-- <ng-template #addButton>
      <button mat-raised-button color="primary" (click)="addImageToProject(image)">
        <mat-icon>save</mat-icon>
        <span i18n>ADD TO PROJECT</span>
      </button>
    </ng-template> -->

    <span class = "flex-auto"></span>
    <button mat-raised-button color="primary" (click)="print(image)">
      <mat-icon>print</mat-icon>
      <span i18n>PRINT</span>
    </button>
    <button mat-raised-button color="primary" (click)="saveImage(image)">
      <mat-icon>save</mat-icon>
      <span i18n>SAVE IMAGE</span>
    </button>
  </mat-dialog-actions>
</ng-container>

<!-- <ng-template #thumbTemplate let-data="data" let-index="index">
  <button mat-icon-button class="remove-image" (click)="removeImage(data, index)">
    <mat-icon>delete</mat-icon>
  </button>
</ng-template> -->
