<div fxLayout="row" fxLayoutAlign="center center">
    <img src="/assets/img/logo.png">
    <span class="mat-display-1 push-none pad-sm-left">WebPlanner</span>
</div>
<mat-divider class="push"></mat-divider>
<div fxLayout="column">
  <p class="mat-subheading-1">&copy; 2015 - 2021
    <br/>Колесников Роман
    <br/>ООО Базис-Центр
    <br/>Все права защищены
  </p>
  <p class="mat-subheading-1"><span i18n>Version</span>: {{version}}</p>
  <p class="mat-subheading-1"><span i18n>Build</span>: {{commit}}</p>
  <p class="mat-subheading-1"><span i18n>Date</span>: {{date}}</p>
</div>
