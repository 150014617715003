<h1 matDialogTitle>
  <span i18n>Link to current model</span>
</h1>
<div mat-dialog-content fxLayout="column">
  <mat-form-field fxFlex>
    <input
      #linkElem
      matInput
      [value]="url | async"
      readonly="true"
      class="link-input"
    />
  </mat-form-field>
  <div fxLayout="row">
    <mat-checkbox *ngIf="data.editableUrl" [formControl]="editable" i18n
      >Editable link</mat-checkbox
    >
    <span fxFlex></span>
    <button
      mat-icon-button
      color="primary"
      i18n-matTooltip
      matTooltip="View QR Code"
      (click)="showQRCode()"
    >
      <mat-icon svgIcon="qr_code"></mat-icon>
    </button>
    <button
      mat-icon-button
      color="primary"
      shareButton="whatsapp"
      [url]="url | async"
      i18n-matTooltip
      matTooltip="Share in WhatsApp"
    >
      <mat-icon svgIcon="whatsapp"></mat-icon>
    </button>
    <button
      mat-icon-button
      color="primary"
      shareButton="vk"
      [url]="url | async"
      i18n-matTooltip
      matTooltip="Share in vk.com"
    >
      <mat-icon svgIcon="vk"></mat-icon>
    </button>
    <button
      mat-icon-button
      color="primary"
      shareButton="facebook"
      [url]="url | async"
      i18n-matTooltip
      matTooltip="Share in Facebook"
    >
      <mat-icon svgIcon="facebook"></mat-icon>
    </button>
    <button
      mat-icon-button
      color="primary"
      (click)="copy()"
      i18n-matTooltip
      matTooltip="Copy link"
    >
      <mat-icon>content_copy</mat-icon>
    </button>
  </div>
  <app-message *ngIf="copied" color="primary" i18n
    >Link copied to clipboard</app-message
  >
</div>
<div mat-dialog-actions align="end">
  <button mat-raised-button matDialogClose i18n>CLOSE</button>
</div>
