import { Entity } from "modeler/designer";
import { AuthService, FileOrderItem, FilesService, CatalogService } from "app/shared";
import { EstimateService } from "./estimate";
import { concatMap, map, tap } from "rxjs/operators";
import { ProjectEditorInterface } from "./editor.script";
import { DatePipe } from "@angular/common";
import { ChangeDetectorRef } from "@angular/core";
import { PlannerUI } from './ui.script';
import { SystemService } from "app/shared/system.service";
import { ProjectHandler } from "modeler/project-handler";
import { WebDesigner } from "modeler/webdesigner";
import { Observable } from "rxjs";
import { locatePoint, LocateOptions } from "modeler/snap-locators";
import { EditorPropertyGroup } from "modeler/property-editor";

interface PlannerInterface {
  ds: WebDesigner;
  embedded: boolean;
  fileItem?: FileOrderItem;
  fileToken?: string;
  project: ProjectHandler;
  getViewLink(token?: string): Observable<string>;
  enablePaintMode(): void;
  addWalls(): void;
  splitWall(): void;
  removeAuxLines(): void;
  symmetryEntity(): void;
  advancedCopy(): void;
  moveSelection(): void;
  replaceSelection(): void;
  bulkReplace(): void;
  hideSelection(): void;
  rotateSelection(): void;
  undo(): void;
  redo(): void;
  shareLinkDialog(options?: {name?: string, email?: string, emails?: string[]});
  setEditable(value: boolean);
  downloadFile(format: string, root: string, materials: boolean, saveAs: boolean): Observable<Blob>;
  // services
  system: SystemService;
  auth: AuthService;
  files: FilesService;
  catalogs: CatalogService;
  estimate: EstimateService;
  datePipe: DatePipe;
  ui: PlannerUI;
  cd: ChangeDetectorRef;
  propertyEditor: EditorPropertyGroup;
}

export class PlannerScriptInterface {
  constructor(private _planner: PlannerInterface) {}

  private _actions = {
    selectAll: () => this._planner.project.selectAll(),
    animateSelected: () => this._planner.project.animateAll(this.selected),
    animateAll: () => this._planner.project.animateAll(null, 1),
    closeAll: () => this._planner.project.animateAll(null, 0),
    enablePaintMode: () => this._planner.enablePaintMode(),
    addWalls: () => this._planner.addWalls(),
    splitWalls: () => this._planner.splitWall(),
    removeAuxLines: () => this._planner.removeAuxLines(),
    symmetryEntity: () => this._planner.symmetryEntity(),
    rotateSelection: (angle?: number) => angle ? this._planner.ds.rotateSelection(angle) : this._planner.rotateSelection(),
    copySelection: () => this._planner.project.copySelection(),
    advancedCopy: () => this._planner.advancedCopy(),
    moveSelection: () => this._planner.moveSelection(),
    replaceSelection: () => this._planner.replaceSelection(),
    bulkReplace: () => this._planner.bulkReplace(),
    removeSelection: () => this._planner.project.removeSelection(),
    hideSelection: () => this._planner.hideSelection(),
    undo: () => this._planner.undo(),
    redo: () => this._planner.redo(),
    shareLinkDialog: (options?) => this._planner.shareLinkDialog(options)
  };

  get actions() {
    return this._actions;
  }

  get root() {
    return this._planner.ds.root;
  }

  get selected() {
    return this._planner.ds.selected;
  }

  get selectedItems() {
    return this._planner.ds.selectedItems;
  }

  findAll(filter: (e: Entity) => boolean) {
    let result = [];
    this.root.forAll(e => {
      if (filter(e)) {
        result.push(e);
      }
    });
    return result;
  }

  findBindingPoint(point: {x: number, y: number}, params: LocateOptions & {root?: Entity} = {}) {
    let ds = this._planner.ds;
    let root = params.root || ds.root;
    return locatePoint(point, root, root.windowMatrix, params);
  }

  get layers() {
    return this._planner.ds.layers;
  }

  findById(id: string) {
    return this._planner.ds.entityMap[id];
  }

  get embedded() {
    return this._planner.embedded;
  }

  get propertyEditor() {
    return this._planner.propertyEditor;
  }

  get user() {
    let auth = this._planner.auth;
    return auth.isAuthenticated.value ? {
      id: auth.userId,
      name: auth.userName,
      roles: auth.roles,
      address: auth.address,
      phone: auth.phone,
      fullName: auth.fullName,
      email: auth.email
    } : undefined;
  }

  get project() {
    let file = this._planner.fileItem;
    let preview = file.preview &&
      `${this._planner.auth.host}/previews/${file.preview.substr(0, 2)}/${file.preview}`;
    return {
      id: file.id,
      name: file.name,
      url: this._planner.getViewLink(),
      params: file.params || {},
      preview,
      getUrlWithToken: (write = false) => {
        return this._planner.files.generateFileTokens(file.id, this._planner.fileToken).pipe(
          concatMap(tokens => {
            let token = '';
            if (file.shared) {
              token = write ? tokens.write : undefined;
            } else {
              token = write ? tokens.write : tokens.read
            }
            return this._planner.getViewLink(token);
          })
        ).toPromise();
      },
      lock: (lock = true) => {
        return this._planner.files.lock(file.id, lock).pipe(
          tap(_ => this._planner.setEditable(!lock))
        ).toPromise();
      },
      export : (format: string, root?: Entity) => {
        let rootId = root ? root.uidStr : '';
        return this._planner.downloadFile(format, rootId, true, false).toPromise();
      }
    }
  }

  get estimate() {
    return this._planner.estimate;
  }

  get ui() {
    return this._planner.ui;
  }

  get editor() {
    return new ProjectEditorInterface(this._planner.ds, this._planner.files, this._planner.catalogs);
  }

  get camera() {
    return this._planner.ds.camera;
  }

  startEditor(options = { batch: true, amend: false }) {
    return new ProjectEditorInterface(this._planner.ds, this._planner.files, this._planner.catalogs,
      options.batch !== false, options.amend === true);
  }

  get order() {
    this._planner.fileItem.client = this._planner.fileItem.client || { name: '' };
    return this._planner.fileItem;
  }

  get date() {
    return this._planner.datePipe.transform(new Date(), 'dd.MM.yyyy');
  }

  onLoad?: () => void;
  onSelect?: (status: any) => void;

  get changed$() {
    return this._planner.ds.modelChange.pipe(map(type => {
      let ds = this._planner.ds;
      return {
        revision: ds.lastSyncRevision,
        undo: !!ds.undoName,
        redo: !!ds.redoName,
        external: type !== 'sync',
      };
    }));
  }

  loadModules(...urls: string[]) {
    let loaderCode = 'return Array.from(arguments).map(url => import(url))';
    let loaders = new Function(loaderCode).apply(this, urls);
    return Promise.all(loaders).then((modules: any[]) => {
      let results = [];
      for (let module of modules) {
        let result = null;
        if (module && typeof module.WebPlannerInit === 'function') {
          result = module.WebPlannerInit(this);
        }
        results.push(result);
      }
      return results;
    });
  }

  _initialized() {
    this._planner.cd.markForCheck();
  }
}
