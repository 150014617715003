import { Component, OnInit, Input } from '@angular/core';
import { EntityProperty, ModelHandler } from 'modeler/model-handler';
import { ElasticParamView, Entity } from 'modeler/designer';
import { CatalogMaterialCache } from 'app/shared';
import { PropertyVariant, Property } from 'modeler/model-properties';
import { MatSelectChange } from '@angular/material/select';
import { WebDesigner } from 'modeler/webdesigner';
import { Observable } from 'rxjs';
import { MathCalculator } from 'modeler/math-calculator';

@Component({
  selector: 'tbody[props-and-params]',
  templateUrl: './props-and-params.component.html',
  styleUrls: ['./props-and-params.component.scss']
})
export class PropsAndParamsComponent implements OnInit {

  @Input() propsAndParams: {props: EntityProperty[], params: ElasticParamView[]}
  @Input() selectedItems: Entity[];
  @Input() materialCache: CatalogMaterialCache;
  @Input() ds: WebDesigner;
  @Input() propertyLoader: (id: any) => Observable<Property>;

  get props() {
    return this.propsAndParams.props;
  }

  get params() {
    return this.propsAndParams.params;
  }

  modelpropertyExpanded = new Set<number>();


  constructor() { }

  findMaterial(property: EntityProperty, variant?: PropertyVariant) {
    return this.materialCache.get(property.findMaterial(variant));
  }

  propertyValueChanged(p: EntityProperty, event: MatSelectChange) {
    let items = p.elements.map(el => el.e);
    if (event.value) {
      ModelHandler.applyProperty(this.ds, p.id, event.value,
        items, this.propertyLoader).subscribe();
    }
  }

  findValueMaterial(property: EntityProperty, value: number) {
    let variant = property.find(value);
    return variant && this.materialCache.get(property.findMaterial(variant));
  }


  resizeModel(axis: string, value: string | number, param?: ElasticParamView) {
    let newSize = {};
    let min = param ? -MathCalculator.MAX_RANGE : 1;
    let floatValue = MathCalculator.calculateRange(value, min);
    newSize[axis] = floatValue;
    let entities = param ? param.entitites : this.selectedItems;
    let items = entities.map(e => {
      return {uid: e, size: newSize};
    });
    this.ds.applyBatch("Resize model", items);
  }

  fieldAppearance(writeable: boolean) {
    return writeable ? 'outline' : 'fill';
  }

  paramTrackBy = (_: number, item: ElasticParamView) => item.name;

  propertyTrackBy = (_: number, item: EntityProperty) => item.id;

  ngOnInit() {}

}
