import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PriceListsComponent } from './price-lists/price-lists.component';
import { PricelistDetailComponent } from './pricelist-detail/pricelist-detail.component';
import { ThumbnailPipe, BumpThumbnailPipe, BumpmapPipe, TexturePipe } from './catalog.service';
import { PreviewPipe } from './files.service';
import { FloatPipe } from './units.service';
import { WpTimeAgoPipe } from './time-ago.pipe';
import { MaterialModule } from './material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { WaitDirective } from './wait.directive';
import { SearchModule } from './search.module';
import { WpBytesPipe } from './bytes.pipe';
import { CovalentFileModule } from './file/file.module';
import { CovalentDynamicFormsModule } from './dynamic-forms/dynamic-forms.module';
import { LetDirective } from './let.directive';
import { PblNgridConfigService, PblNgridModule } from '@pebula/ngrid';
import { PblNgridMaterialModule } from '@pebula/ngrid-material';
import { NGridTableTemplatesComponent } from './common-table-templates/common-table-templates.component';
import { PblNgridTargetEventsModule } from '@pebula/ngrid/target-events';
import { PblNgridBlockUiModule } from '@pebula/ngrid/block-ui';

@NgModule({
  declarations: [
    // pipes
    ThumbnailPipe,
    BumpThumbnailPipe,
    BumpmapPipe,
    TexturePipe,
    PreviewPipe,
    FloatPipe,
    WpTimeAgoPipe,
    WpBytesPipe,
    PriceListsComponent,
    PricelistDetailComponent,
    WaitDirective,
    LetDirective,
    NGridTableTemplatesComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    CovalentDynamicFormsModule,
    CovalentFileModule,
    SearchModule,
    RouterModule,
    PblNgridModule.withCommon([ { component: NGridTableTemplatesComponent } ]),
    PblNgridMaterialModule,
    PblNgridTargetEventsModule,
    PblNgridBlockUiModule
  ],
  exports: [
    ThumbnailPipe,
    BumpThumbnailPipe,
    BumpmapPipe,
    TexturePipe,
    PreviewPipe,
    FloatPipe,
    WpTimeAgoPipe,
    WpBytesPipe,
    WaitDirective,
    LetDirective,
    MaterialModule,
    PblNgridModule,

    PblNgridMaterialModule,
    PblNgridTargetEventsModule,
    PblNgridBlockUiModule,
    PriceListsComponent,
    PricelistDetailComponent,
    MaterialModule,
    CovalentDynamicFormsModule,
    CovalentFileModule,
    FlexLayoutModule,
    SearchModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class SharedModule {
  constructor(config: PblNgridConfigService) {
    config.set('targetEvents', { autoEnable: true });
  }
}
